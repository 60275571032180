import { defineStore } from 'pinia'
import type { Service, Breadcrumb, SnackbackMessage } from '~/types';

const home = { title: 'Home', to: '/admin/' }

export const usePageStore = defineStore({
  id: 'pageStore',
  state: () => ({
    breadcrumbs: [] as Breadcrumb[],
    snackbars: [] as SnackbackMessage[],
    cart: [] as typeof Service[],
    mobileMenuDrawer: false,
  }),
  getters: {
    cartTotal(): number {
      return this.cart.reduce((acc, service) => acc + (service.price || 0), 0);
    },
    cartCount(): number {
      return this.cart.length
    }
  },
  actions: {
    setBreadcrumbs(breadcrumbs: Breadcrumb[]) {
      this.breadcrumbs = [home, ...breadcrumbs]
    },

    // toasts

    appendBreadcrumbs(breadcrumb: Breadcrumb) {
      this.breadcrumbs.push(breadcrumb)
    },
    showSuccessToast(message: string) {
      this.snackbars.push({ message, color: 'success', show: true })
    },
    showErrorToast(message: string) {
      this.snackbars.push({ message, color: 'error', show: true })
    },

    // cart

    addToCart(service: typeof Service) {
      this.cart.push(service)
    },
    removeFromCart(service: typeof Service) {
      this.cart = this.cart.filter(s => s.id !== service.id)
    },
    clearCart() {
      this.cart = []
    },

    toggleMobileMenuDrawer() {
      this.mobileMenuDrawer = !this.mobileMenuDrawer
    }
  }
})
